import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink } from '../../../components';

import css from './NoSearchResultsMaybe.module.css';
import { isApprovedOwner, isApprovedSeller } from '../../../util/userHelpers';
import classNames from 'classnames';

const NoSearchResultsMaybe = props => {
  const { listingsAreLoaded, totalItems, location, resetAll, isSearch = true, label = "SearchPage.noResults", isAuthenticated = false, currentUser = null } = props;
  const hasNoResult = listingsAreLoaded && totalItems === 0;
  const hasSearchParams = location.search?.length > 0;

  let signUpBtn = <p className={css.pContainer}>
    <NamedLink className={css.providerSignupLink} name="SignupForUserTypePage" params={{ userType: 'provider' }}>
      <FormattedMessage id="TopbarDesktop.providerSignup" />
    </NamedLink>
  </p>;

  const isApproved = isApprovedSeller(currentUser);
  const isOwner = isApprovedOwner(currentUser);
  const canCreateListings = isAuthenticated && !!currentUser && isApproved && !isOwner;

  let createListingBtn = canCreateListings ? <p>
    <NamedLink className={classNames(css.createListingLink, css.customCreateListingLink)} name="NewListingPage">
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.createListing" />
      </span>
    </NamedLink>
  </p> : null;

  return hasNoResult ? (
    <div className={css.noSearchResults}>
      <FormattedMessage id={label} />
      <br />
      {hasSearchParams && isSearch ? (
        <button className={css.resetAllFiltersButton} onClick={e => resetAll(e)}>
          <FormattedMessage id={'SearchPage.resetAllFilters'} />
        </button>
      ) : null}
      {isSearch ? (isAuthenticated ? createListingBtn : signUpBtn) : null}
    </div>
  ) : null;
};

export default NoSearchResultsMaybe;
