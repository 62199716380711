import React from 'react';
import { string, func, bool, array } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { intlShape, injectIntl } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import { AspectRatioWrapper, NamedLink } from '../../components';

import css from './ListingCard.module.css';

const _ = require('lodash');

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const { REACT_APP_AMAZON_S3_BUCKET_NAME: Bucket } = process.env;

export const StoreCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    store,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const id = store?.id?.uuid;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
  } = config.layout.listingImage;

  const MAX_TITLE_LENGTH = 20;

  let storeProps = {
    name: 'TeamPage',
    params: { id, type: 'detail' },
  };

  let { publicData } = store?.attributes?.profile || {};
  let { businessName = '', businessProfilePicture } = publicData;
  let { place_name } = publicData?.storedAddress || {};

  let imageUrl = `https://${Bucket}.S3.amazonaws.com/pictures/${businessProfilePicture}`;

  return (<NamedLink className={classNames(classes, css.storeCardContainer)} {...storeProps}>
      <div className={css.store}>
        <img src={imageUrl} className={css.storeImage}/>
      </div>
      <div className={css.mainSectionInfo}>
        <div className={classNames(css.listingMainInfo, css.storeMainInfo)}>
          <div className={css.categoryTitle}>
            <div>
              {richText(businessName.substring(0, MAX_TITLE_LENGTH), {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
          </div>
          {!!place_name && place_name !== '' ? <div className={css.storeAddress}>{place_name}</div> : null}
        </div>
      </div>
  </NamedLink>);
};

StoreCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

StoreCardComponent.propTypes = {
  className: string,
  rootClassName: string,
};

export default injectIntl(StoreCardComponent);
